<template>
  <div class="" v-if="transaction">
    <trac-back-button>Back</trac-back-button>

    <div class="flex justify-center my-4">
      <img
        width="200px"
        src="./../../assets/svg/traction_wallet_transaction_receipt_logo.svg"
        alt=""
      />
    </div>
    <div class="shadow-sm rounded-md text-center p-5">
      <h3 class="text-xl font-bold mb-3">Transactions Receipt</h3>

      <div class="flex justify-between">
        <p class="font-bold">Transaction Details</p>
        <span></span>
      </div>

      <div class="flex justify-between my-3">
        <span class="font-thin">Transaction Date:</span>
        <p class="font-bold">{{ moment(transaction.createdOn).format("Do MMM YYYY") }}</p>
      </div>
      <div class="flex justify-between my-3">
        <span class="font-thin">Transaction Amount:</span>
        <p class="font-bold">{{ transaction.amount | formatPrice(false) }}</p>
      </div>
      <!-- <div class="flex justify-between my-3">
        <span class="font-thin">Amount in words:</span>
        <p class="font-bold">Three thousand naira only</p>
      </div> -->
      <div class="flex justify-between my-3">
        <span class="font-thin">Transaction Type:</span>
        <p class="font-bold">{{ transaction.transactiontypename }}</p>
      </div>
      <div class="flex justify-between my-3">
        <span class="font-thin">Reference Number:</span>
        <p class="font-bold">{{ transaction.reference }}</p>
      </div>
      <div class="flex justify-between my-3">
        <span class="font-thin">Transaction Status:</span>
        <p class="font-bold">{{ transaction.transactionstatus }}</p>
      </div>

      <hr class="my-6" />

      <div class="" v-if="transaction.transactiontypename === 'WalletToBank'">
      <div class="flex justify-between">
        <p class="font-bold">Beneficiary Details</p>
        <span></span>
      </div>

      <div class="flex justify-between my-3">
        <span class="font-thin">Account Number:</span>
        <p class="font-bold">{{ transaction.craccount }}</p>
      </div>

      <div class="flex justify-between my-3">
        <span class="font-thin">Account Name:</span>
        <p class="font-bold">{{ transaction.craccountname }}</p>
      </div>

      <div class="flex justify-between my-3">
        <span class="font-thin">Bank:</span>
        <p class="font-bold">{{ transaction.bankname }}</p>
      </div>
      </div>

      <div class="border-dotted border-2 my-4 p-3">
        <div class="flex justify-between">
          <p class="font-bold text-xs">Disclaimer</p>
          <span></span>
        </div>

        <p class="text-justify text-xs">
          Your money has been sent and the receiver’s account will be created.
          Please note that this does not confirm that the receives account has
          been credited. You should also know that transfer and payment are
          checked for fraud and require verification. Traction will not take
          responsibility for any fault outside our control that could affect the
          delivery of the sent customer.
        </p>
      </div>
    </div>
    <!-- <div class="flex justify-end mt-8">
      <trac-button>Download</trac-button>
    </div> -->
  </div>
</template>

<script>
import { DELETE_LOCAL_DB_DATA, GET_LOCAL_DB_DATA } from '../../browser-db-config/localStorage';
import moment from "moment";

export default {
  data() {
    return {
      moment,
      transaction: null,
    };
  },
  created() {
    this.transaction = GET_LOCAL_DB_DATA('wallet_transaction');
    if (!this.transaction) {
      this.$router.replace({name: 'MainWallet'});
    }
  },
  destroyed() {
    DELETE_LOCAL_DB_DATA('wallet_transaction');
  }
};
</script>

<style>
</style>